import { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";

// constants
import { breakpoints } from "@/constants";

export function useResponsive() {
  const [isClient, setIsClient] = useState(false);

  const isMobile = useMediaQuery({
    maxWidth: breakpoints.MD - 1,
  });

  const isDesktop = useMediaQuery({
    minWidth: breakpoints.MD,
  });

  useEffect(() => {
    // validate window
    if (typeof window === "undefined") return;
    setIsClient(true);
  }, []);

  return {
    isMobile: isClient ? isMobile : false,
    isDesktop: isClient ? isDesktop : true,
  };
}
