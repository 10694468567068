import { useTranslation } from "react-i18next";

import { BaseLayout, CTAList } from "@/components";
import type { CTA } from "@/components";

// hooks
import { useLocaleUrl } from "@/hooks";

// constants
import { WHATSAPP_URL, TELEGRAM_URL, DISCORD_URL } from "@/constants";

const PeerSupportPlatforms = () => {
  const { t } = useTranslation();

  const { toLocaleUrl } = useLocaleUrl();

  const ctaList: CTA[] = [
    {
      icon: "story-icon-whatsapp",
      text: t("peerSupport.platforms.whatsapp"),
      onClick: () => {
        window.open(WHATSAPP_URL, "_blank");
      },
    },
    {
      icon: "story-icon-telegram",
      text: t("peerSupport.platforms.telegram"),
      onClick: () => {
        window.open(TELEGRAM_URL, "_blank");
      },
    },
    {
      icon: "story-icon-discord",
      text: t("peerSupport.platforms.discord"),
      onClick: () => {
        window.open(DISCORD_URL, "_blank");
      },
    },
  ];

  return (
    <BaseLayout backUrl={toLocaleUrl("/peer-support/instructions")}>
      <div className="koko-page__peer-support__platforms">
        <div className="header">
          <span className="title">
            {t("peerSupport.platforms.header.title")}
          </span>
          <p className="subtitle">
            {t("peerSupport.platforms.header.subtitle")}
          </p>
        </div>
        <div className="content">
          <CTAList items={ctaList} />
        </div>
      </div>
    </BaseLayout>
  );
};

export { PeerSupportPlatforms };
