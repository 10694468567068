export const KOKO_CARES_URL = "https://www.kokocares.org";

export const PRIVACY_POLICY_URL = "https://www.kokocares.org/privacy-policy";
export const TERMS_OF_SERVICE_URL =
  "https://www.kokocares.org/terms-of-use-agreement/";

export const WHATSAPP_URL = "https://wa.me/16056565650";
export const TELEGRAM_URL =
  "https://telegram.me/TheKokoBot?start=support_navigator";
export const DISCORD_URL = "https://discord.gg/koko-cares-943305768495620096";

export const CREATE_SAFETY_PLAN_URL =
  "https://r.kokocares.org/support-navigator/safety-plan";
export const IMPROVE_MOOD_URL = "https://r.kokocares.org/support-navigator/abc";
export const MANAGE_SELF_HARM_URL =
  "https://r.kokocares.org/support-navigator/self-harm";
export const BODY_IMAGE_URL =
  "https://r.kokocares.org/support-navigator/body-image";
