/* eslint-disable max-len */
import { FC } from "react";

// types
import { GlyphIcon } from "../Icon.types";

const StoryIconLamp: FC<GlyphIcon> = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      width={50}
      height={50}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z"
        fill="url(#paint0_linear_578_889)"
      />
      <path
        opacity={0.2}
        d="M19.5312 33.8281V40C19.5312 40.3906 20 41.0156 20.3125 41.1719L24.4531 43.5156C24.7656 43.6719 25.3125 43.6719 25.625 43.5156L29.7656 41.1719C30.0781 41.0156 30.5469 40.3125 30.5469 40V33.8281C28.9062 34.6875 27.0312 35.1563 25.0781 35.1563C23.0469 35.1563 21.1719 34.6875 19.5312 33.8281Z"
        fill="#231F20"
      />
      <path
        d="M19.5312 32.2656V38.4375C19.5312 38.8281 20 39.4531 20.3125 39.6094L24.4531 41.9531C24.7656 42.1094 25.3125 42.1094 25.625 41.9531L29.7656 39.6094C30.0781 39.4531 30.5469 38.75 30.5469 38.4375V32.2656C28.9062 33.125 27.0312 33.5938 25.0781 33.5938C23.0469 33.5938 21.1719 33.125 19.5312 32.2656Z"
        fill="#E0E0D1"
      />
      <path
        opacity={0.2}
        d="M25 38.2812C31.2132 38.2812 36.25 33.2444 36.25 27.0312C36.25 20.818 31.2132 15.7812 25 15.7812C18.7868 15.7812 13.75 20.818 13.75 27.0312C13.75 33.2444 18.7868 38.2812 25 38.2812Z"
        fill="#231F20"
      />
      <path
        d="M25 36.25C31.2132 36.25 36.25 31.2132 36.25 25C36.25 18.7868 31.2132 13.75 25 13.75C18.7868 13.75 13.75 18.7868 13.75 25C13.75 31.2132 18.7868 36.25 25 36.25Z"
        fill="white"
      />
      <path
        opacity={0.2}
        d="M26.1719 12.1094C26.1719 12.7344 25.625 13.2812 25 13.2812C24.375 13.2812 23.8281 12.7344 23.8281 12.1094V10.5469C23.8281 9.92187 24.375 9.375 25 9.375C25.625 9.375 26.1719 9.92187 26.1719 10.5469V12.1094Z"
        fill="#231F20"
      />
      <path
        opacity={0.2}
        d="M39.4531 27.7344C38.8281 27.7344 38.2812 27.1875 38.2812 26.5625C38.2812 25.9375 38.8281 25.3906 39.4531 25.3906H41.0156C41.6406 25.3906 42.1875 25.9375 42.1875 26.5625C42.1875 27.1875 41.6406 27.7344 41.0156 27.7344H39.4531Z"
        fill="#231F20"
      />
      <path
        opacity={0.2}
        d="M10.5469 25.3906C11.1719 25.3906 11.7187 25.9375 11.7187 26.5625C11.7187 27.1875 11.1719 27.7344 10.5469 27.7344H8.98437C8.35937 27.7344 7.8125 27.1875 7.8125 26.5625C7.8125 25.9375 8.35937 25.3906 8.98437 25.3906H10.5469Z"
        fill="#231F20"
      />
      <path
        opacity={0.2}
        d="M18.75 13.4376C19.0625 13.9845 18.9062 14.6876 18.3593 15.0001C17.8125 15.3126 17.1093 15.1563 16.7968 14.6095L16.0156 13.2813C15.7031 12.7345 15.8593 12.0313 16.4062 11.7188C16.9531 11.4063 17.6562 11.5626 17.9687 12.1095L18.75 13.4376Z"
        fill="#231F20"
      />
      <path
        opacity={0.2}
        d="M38.125 20.3126C37.5781 20.6251 36.875 20.4688 36.5625 19.922C36.25 19.3751 36.4062 18.672 36.9531 18.3595L38.2812 17.5782C38.8281 17.2657 39.5312 17.422 39.8437 17.9688C40.1562 18.5157 40 19.2188 39.4531 19.5313L38.125 20.3126Z"
        fill="#231F20"
      />
      <path
        opacity={0.2}
        d="M11.875 32.8126C12.4218 32.5001 13.125 32.6563 13.4375 33.2032C13.75 33.7501 13.5937 34.4532 13.0468 34.7657L11.7187 35.547C11.1718 35.8595 10.4687 35.7032 10.1562 35.1563C9.84372 34.6095 9.99997 33.9063 10.5468 33.5938L11.875 32.8126Z"
        fill="#231F20"
      />
      <path
        opacity={0.2}
        d="M13.047 18.3595C13.5939 18.672 13.8283 19.3751 13.4376 19.922C13.1251 20.4689 12.422 20.7033 11.8751 20.3126L10.547 19.5314C10.0001 19.2189 9.76575 18.5158 10.1564 17.9689C10.4689 17.422 11.172 17.1876 11.7189 17.5783L13.047 18.3595Z"
        fill="#231F20"
      />
      <path
        opacity={0.2}
        d="M36.9533 34.7658C36.4064 34.4533 36.172 33.7501 36.5626 33.2033C36.8751 32.6564 37.5783 32.422 38.1251 32.8126L39.4533 33.5939C40.0001 33.9064 40.2345 34.6095 39.8439 35.1564C39.5314 35.7033 38.8283 35.9376 38.2814 35.547L36.9533 34.7658Z"
        fill="#231F20"
      />
      <path
        opacity={0.2}
        d="M33.203 14.6095C32.8905 15.1564 32.1874 15.3908 31.6405 15.0001C31.0936 14.6876 30.8593 13.9845 31.2499 13.4376L32.0311 12.1095C32.3436 11.5626 33.0468 11.3283 33.5936 11.7189C34.1405 12.0314 34.3749 12.7345 33.9843 13.2814L33.203 14.6095Z"
        fill="#231F20"
      />
      <path
        d="M25 36.25C31.2132 36.25 36.25 31.2132 36.25 25C36.25 18.7868 31.2132 13.75 25 13.75C18.7868 13.75 13.75 18.7868 13.75 25C13.75 31.2132 18.7868 36.25 25 36.25Z"
        fill="white"
      />
      <path
        d="M26.1719 10.5469C26.1719 11.1719 25.625 11.7187 25 11.7187C24.375 11.7187 23.8281 11.1719 23.8281 10.5469V8.98437C23.8281 8.35937 24.375 7.8125 25 7.8125C25.625 7.8125 26.1719 8.35937 26.1719 8.98437V10.5469Z"
        fill="#E0E0D1"
      />
      <path
        d="M39.4531 26.1719C38.8281 26.1719 38.2812 25.625 38.2812 25C38.2812 24.375 38.8281 23.8281 39.4531 23.8281H41.0156C41.6406 23.8281 42.1875 24.375 42.1875 25C42.1875 25.625 41.6406 26.1719 41.0156 26.1719H39.4531Z"
        fill="#E0E0D1"
      />
      <path
        d="M10.5469 23.8281C11.1719 23.8281 11.7187 24.375 11.7187 25C11.7187 25.625 11.1719 26.1719 10.5469 26.1719H8.98437C8.35937 26.1719 7.8125 25.625 7.8125 25C7.8125 24.375 8.35937 23.8281 8.98437 23.8281H10.5469Z"
        fill="#E0E0D1"
      />
      <path
        d="M18.75 11.8751C19.0625 12.422 18.9062 13.1251 18.3593 13.4376C17.8125 13.7501 17.1093 13.5938 16.7968 13.047L16.0156 11.7188C15.7031 11.172 15.8593 10.4688 16.4062 10.1563C16.9531 9.84384 17.6562 10.0001 17.9687 10.547L18.75 11.8751Z"
        fill="#E0E0D1"
      />
      <path
        d="M38.125 18.7501C37.5781 19.0626 36.875 18.9063 36.5625 18.3595C36.25 17.8126 36.4062 17.1095 36.9531 16.797L38.2812 16.0157C38.8281 15.7032 39.5312 15.8595 39.8437 16.4063C40.1562 16.9532 40 17.6563 39.4531 17.9688L38.125 18.7501Z"
        fill="#E0E0D1"
      />
      <path
        d="M11.875 31.2501C12.4218 30.9376 13.125 31.0938 13.4375 31.6407C13.75 32.1876 13.5937 32.8907 13.0468 33.2032L11.7187 33.9845C11.1718 34.297 10.4687 34.1407 10.1562 33.5938C9.84372 33.047 9.99997 32.3438 10.5468 32.0313L11.875 31.2501Z"
        fill="#E0E0D1"
      />
      <path
        d="M13.0468 16.797C13.5936 17.1095 13.828 17.8126 13.4374 18.3595C13.1249 18.9064 12.4218 19.1408 11.8749 18.7501L10.5468 17.9689C9.99988 17.6564 9.76551 16.9533 10.1561 16.4064C10.4686 15.8595 11.1718 15.6251 11.7186 16.0158L13.0468 16.797Z"
        fill="#E0E0D1"
      />
      <path
        d="M36.953 33.2033C36.4061 32.8908 36.1718 32.1876 36.5624 31.6408C36.8749 31.0939 37.578 30.8595 38.1249 31.2501L39.453 32.0314C39.9999 32.3439 40.2343 33.047 39.8436 33.5939C39.5311 34.1408 38.828 34.3751 38.2811 33.9845L36.953 33.2033Z"
        fill="#E0E0D1"
      />
      <path
        d="M33.203 13.047C32.8905 13.5939 32.1874 13.8283 31.6405 13.4376C31.0936 13.1251 30.8593 12.422 31.2499 11.8751L32.0311 10.547C32.3436 10.0001 33.0468 9.76575 33.5936 10.1564C34.1405 10.4689 34.3749 11.172 33.9843 11.7189L33.203 13.047Z"
        fill="#E0E0D1"
      />
      <defs>
        <linearGradient
          id="paint0_linear_578_889"
          x1={25}
          y1={0}
          x2={25}
          y2={50}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF3B41" />
          <stop offset={1} stopColor="#FD5181" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default StoryIconLamp;
