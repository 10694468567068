const ENV = process.env.REACT_APP_ENV || process.env.NODE_ENV || "dev";

const getConfig = () => {
  switch (ENV) {
    case "prod":
      return {
        api: {
          timeout: 60000,
        },
        ipinfo: {
          baseUrl: "https://ipinfo.io",
          token: "d08e8c23aa0455",
        },
      };
    case "dev":
      return {
        api: {
          timeout: 60000,
        },
        ipinfo: {
          baseUrl: "https://ipinfo.io",
          token: "d08e8c23aa0455",
        },
      };
    case "local":
    default:
      return {
        api: {
          timeout: 60000,
        },
        ipinfo: {
          baseUrl: "https://ipinfo.io",
          token: "d08e8c23aa0455",
        },
      };
  }
};

export default getConfig();
