/* eslint-disable max-len */
import { FC } from "react";

// types
import { GlyphIcon } from "../Icon.types";

const StoryIconHeart: FC<GlyphIcon> = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      width={50}
      height={50}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z"
        fill="url(#paint0_linear_578_304)"
      />
      <path
        opacity={0.2}
        d="M39.0625 24.2188C38.9844 19.9219 35.4688 16.0938 31.1719 16.0938C28.6719 16.0938 26.4844 17.4219 25 19.2969C23.5156 17.3438 21.3281 16.0938 18.8281 16.0938C14.5313 16.0938 11.0156 19.9219 10.9375 24.2188V24.2969V24.375V24.6875C11.3281 35.7031 24.8437 40.1562 24.8437 40.1562C24.8437 40.1562 38.5938 35.7031 39.0625 24.6875C39.0625 24.5312 39.0625 24.4531 39.0625 24.375C39.0625 24.375 39.0625 24.2969 39.0625 24.2188Z"
        fill="#231F20"
      />
      <path
        d="M39.0625 22.6562C38.9844 18.3594 35.4688 14.5312 31.1719 14.5312C28.6719 14.5312 26.4844 15.8594 25 17.7344C23.5156 15.7813 21.3281 14.5312 18.8281 14.5312C14.5313 14.5312 11.0156 18.3594 10.9375 22.6562V22.7344V22.8125V23.125C11.3281 34.1406 24.8437 38.5938 24.8437 38.5938C24.8437 38.5938 38.5938 34.1406 39.0625 23.125C39.0625 22.9688 39.0625 22.8906 39.0625 22.8125C39.0625 22.8125 39.0625 22.7344 39.0625 22.6562Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_578_304"
          x1={25}
          y1={0}
          x2={25}
          y2={50}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF3B41" />
          <stop offset={1} stopColor="#FD5181" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default StoryIconHeart;
