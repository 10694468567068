import { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// context
import { useAppContext } from "@/context";

// helpers
import { getLocaleUrlPrefixByLang } from "@/helpers";

// pages
import {
  Countries,
  FAQIntroduction,
  FAQOptions,
  FAQInstructions,
  Helplines,
  Main,
  PeerSupportInstructions,
  PeerSupportPlatforms,
  Resources,
  SelfHelpCourses,
} from "@/pages";

// types
import { LOCALE_CODES } from "@/types";

const App = () => {
  const { defaultLocale } = useAppContext();

  const { pathname } = window.location;

  useEffect(() => {
    if (pathname === "/" && defaultLocale !== LOCALE_CODES.EN) {
      window.location.href = getLocaleUrlPrefixByLang(defaultLocale);
    }
  }, [defaultLocale, pathname]);

  const localeParam = pathname.split("/")[1];

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:locale?/*">
          <Route index element={<Main />} />
          <Route path="countries" element={<Countries />} />
          <Route path="faq/introduction" element={<FAQIntroduction />} />
          <Route path="faq/options" element={<FAQOptions />} />
          <Route path="faq/instructions" element={<FAQInstructions />} />
          <Route path="helplines" element={<Helplines />} />
          <Route
            path="peer-support/instructions"
            element={<PeerSupportInstructions />}
          />
          <Route
            path="peer-support/platforms"
            element={<PeerSupportPlatforms />}
          />
          <Route path="resources" element={<Resources />} />
          <Route path="self-help-courses" element={<SelfHelpCourses />} />

          {/* Not Found Pages */}
          <Route path="*" element={<Navigate to={`/${localeParam}`} />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
