import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";

// helpers
import { isLocaleMatch } from "@/helpers";

// types
import { LOCALE_CODES } from "@/types";

const useLocaleUrl = () => {
  const [locale, setLocale] = useState<LOCALE_CODES>(LOCALE_CODES.EN);

  const { locale: localeParam }: { locale?: LOCALE_CODES } = useParams();

  const toLocaleUrl = useCallback(
    (url: string) => {
      return locale === LOCALE_CODES.EN ? url : `/${locale}${url}`;
    },
    [locale]
  );

  useEffect(() => {
    if (localeParam && isLocaleMatch(localeParam)) {
      setLocale(localeParam);
    } else {
      setLocale(LOCALE_CODES.EN);
    }
  }, [localeParam]);

  return {
    locale,
    isLocaleSupported: localeParam ? isLocaleMatch(localeParam) : true,
    toLocaleUrl,
  };
};

export { useLocaleUrl };
