/* eslint-disable max-len */
import { FC } from "react";

// types
import { GlyphIcon } from "../Icon.types";

const ChevronRight: FC<GlyphIcon> = ({
  className,
  color = "currentColor",
  onClick,
}) => {
  return (
    <svg
      className={className}
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75201 17.648C8.52728 17.423 8.40106 17.118 8.40106 16.8C8.40106 16.482 8.52728 16.177 8.75201 15.952L12.703 12L8.75201 8.04797C8.54004 7.82049 8.42464 7.51962 8.43013 7.20874C8.43561 6.89786 8.56155 6.60124 8.78141 6.38138C9.00127 6.16152 9.29789 6.03558 9.60877 6.03009C9.91965 6.02461 10.2205 6.14001 10.448 6.35197L15.248 11.152C15.4727 11.377 15.599 11.682 15.599 12C15.599 12.318 15.4727 12.623 15.248 12.848L10.448 17.648C10.223 17.8727 9.91801 17.9989 9.60001 17.9989C9.28201 17.9989 8.97701 17.8727 8.75201 17.648Z"
        fill={color}
      />
    </svg>
  );
};

export default ChevronRight;
