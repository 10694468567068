/* eslint-disable max-len */
import { FC } from "react";

// types
import { GlyphIcon } from "../Icon.types";

const Check: FC<GlyphIcon> = ({
  className,
  color = "currentColor",
  onClick,
}) => {
  return (
    <svg
      className={className}
      width={17}
      height={13}
      viewBox="0 0 17 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M5.4025 10.2565L1.37237 6.21402L0 7.58091L5.4025 13L17 1.36689L15.6373 0L5.4025 10.2565Z"
        fill={color}
      />
    </svg>
  );
};

export default Check;
