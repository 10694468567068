import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

// types
import { LOCALE_CODES } from "@/types";

i18next.use(initReactI18next).use(Backend).init({
  // debug: true,
  // saveMissing: true,
  fallbackLng: LOCALE_CODES.EN,
});
