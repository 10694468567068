/* eslint-disable max-len */
import { FC } from "react";

// types
import { GlyphIcon } from "../Icon.types";

const StoryIconFlower: FC<GlyphIcon> = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      width={50}
      height={50}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z"
        fill="url(#paint0_linear_578_322)"
      />
      <path
        d="M25 18.75V38.2812"
        stroke="url(#paint1_linear_578_322)"
        strokeWidth={3.77497}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        opacity={0.2}
        d="M38.9844 29.0625C38.4375 28.75 37.7344 28.8281 37.2656 29.2188C37.2656 29.2188 33.6719 32.1875 29.375 34.5313C27.1875 35.7031 25.8594 37.1875 24.9219 38.5938C24.0625 37.1875 22.6563 35.7031 20.4688 34.5313C16.1719 32.1875 12.6563 29.2969 12.6563 29.2188C12.1875 28.8281 11.4844 28.75 10.9375 28.9844C10.3906 29.2969 10.0781 29.8438 10.0781 30.4688C10.3906 35.3906 13.8281 44.7656 24.8438 44.7656C35.8594 44.7656 39.375 35.3906 39.6875 30.4688C39.8438 29.9219 39.5312 29.375 38.9844 29.0625Z"
        fill="#231F20"
      />
      <path
        d="M25 41.7188C25 41.7188 25 37.1875 30.1562 34.375C34.6875 31.875 38.2812 28.9062 38.2812 28.9062C38.2812 28.9062 37.5 41.7188 25 41.7188Z"
        fill="url(#paint2_linear_578_322)"
        stroke="url(#paint3_linear_578_322)"
        strokeWidth={3.77497}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.9219 41.7188C24.9219 41.7188 24.9219 37.1875 19.7656 34.375C15.3125 31.875 11.7188 28.9062 11.7188 28.9062C11.7188 28.9062 12.5 41.7188 24.9219 41.7188Z"
        fill="url(#paint4_linear_578_322)"
        stroke="url(#paint5_linear_578_322)"
        strokeWidth={3.77497}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity={0.2}
        d="M20.3125 25C23.7643 25 26.5625 22.2018 26.5625 18.75C26.5625 15.2982 23.7643 12.5 20.3125 12.5C16.8607 12.5 14.0625 15.2982 14.0625 18.75C14.0625 22.2018 16.8607 25 20.3125 25Z"
        fill="#231F20"
      />
      <path
        opacity={0.2}
        d="M29.6875 25C33.1393 25 35.9375 22.2018 35.9375 18.75C35.9375 15.2982 33.1393 12.5 29.6875 12.5C26.2357 12.5 23.4375 15.2982 23.4375 18.75C23.4375 22.2018 26.2357 25 29.6875 25Z"
        fill="#231F20"
      />
      <path
        opacity={0.2}
        d="M25 21.875C28.4518 21.875 31.25 19.0768 31.25 15.625C31.25 12.1732 28.4518 9.375 25 9.375C21.5482 9.375 18.75 12.1732 18.75 15.625C18.75 19.0768 21.5482 21.875 25 21.875Z"
        fill="#231F20"
      />
      <path
        opacity={0.2}
        d="M25 29.6875C28.4518 29.6875 31.25 26.8893 31.25 23.4375C31.25 19.9857 28.4518 17.1875 25 17.1875C21.5482 17.1875 18.75 19.9857 18.75 23.4375C18.75 26.8893 21.5482 29.6875 25 29.6875Z"
        fill="#231F20"
      />
      <path
        d="M20.3125 23.4375C23.7643 23.4375 26.5625 20.6393 26.5625 17.1875C26.5625 13.7357 23.7643 10.9375 20.3125 10.9375C16.8607 10.9375 14.0625 13.7357 14.0625 17.1875C14.0625 20.6393 16.8607 23.4375 20.3125 23.4375Z"
        fill="url(#paint6_linear_578_322)"
      />
      <path
        d="M29.6875 23.4375C33.1393 23.4375 35.9375 20.6393 35.9375 17.1875C35.9375 13.7357 33.1393 10.9375 29.6875 10.9375C26.2357 10.9375 23.4375 13.7357 23.4375 17.1875C23.4375 20.6393 26.2357 23.4375 29.6875 23.4375Z"
        fill="url(#paint7_linear_578_322)"
      />
      <path
        d="M25 18.75C28.4518 18.75 31.25 15.9518 31.25 12.5C31.25 9.04822 28.4518 6.25 25 6.25C21.5482 6.25 18.75 9.04822 18.75 12.5C18.75 15.9518 21.5482 18.75 25 18.75Z"
        fill="url(#paint8_linear_578_322)"
      />
      <path
        d="M25 28.125C28.4518 28.125 31.25 25.3268 31.25 21.875C31.25 18.4232 28.4518 15.625 25 15.625C21.5482 15.625 18.75 18.4232 18.75 21.875C18.75 25.3268 21.5482 28.125 25 28.125Z"
        fill="url(#paint9_linear_578_322)"
      />
      <path
        opacity={0.2}
        d="M25 22.6562C27.1574 22.6562 28.9062 20.9074 28.9062 18.75C28.9062 16.5926 27.1574 14.8438 25 14.8438C22.8426 14.8438 21.0938 16.5926 21.0938 18.75C21.0938 20.9074 22.8426 22.6562 25 22.6562Z"
        fill="#231F20"
      />
      <path
        d="M25 21.0938C27.1574 21.0938 28.9062 19.3449 28.9062 17.1875C28.9062 15.0301 27.1574 13.2812 25 13.2812C22.8426 13.2812 21.0938 15.0301 21.0938 17.1875C21.0938 19.3449 22.8426 21.0938 25 21.0938Z"
        fill="#F5CF87"
      />
      <defs>
        <linearGradient
          id="paint0_linear_578_322"
          x1={25}
          y1={0}
          x2={25}
          y2={50}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#75B0FF" />
          <stop offset={1} stopColor="#7059E7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_578_322"
          x1={25.5}
          y1={18.75}
          x2={25.5}
          y2={38.2812}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5DABB5" />
          <stop offset={1} stopColor="#81CA9C" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_578_322"
          x1={31.6406}
          y1={28.9062}
          x2={31.6406}
          y2={41.7188}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5DABB5" />
          <stop offset={1} stopColor="#81CA9C" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_578_322"
          x1={31.6406}
          y1={28.9062}
          x2={31.6406}
          y2={41.7188}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5DABB5" />
          <stop offset={1} stopColor="#81CA9C" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_578_322"
          x1={18.3203}
          y1={28.9062}
          x2={18.3203}
          y2={41.7188}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5DABB5" />
          <stop offset={1} stopColor="#81CA9C" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_578_322"
          x1={18.3203}
          y1={28.9062}
          x2={18.3203}
          y2={41.7188}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5DABB5" />
          <stop offset={1} stopColor="#81CA9C" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_578_322"
          x1={20.3125}
          y1={10.9375}
          x2={20.3125}
          y2={23.4375}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF3B41" />
          <stop offset={1} stopColor="#FD5181" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_578_322"
          x1={29.6875}
          y1={10.9375}
          x2={29.6875}
          y2={23.4375}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF3B41" />
          <stop offset={1} stopColor="#FD5181" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_578_322"
          x1={25}
          y1={6.25}
          x2={25}
          y2={18.75}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF3B41" />
          <stop offset={1} stopColor="#FD5181" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_578_322"
          x1={25}
          y1={15.625}
          x2={25}
          y2={28.125}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF3B41" />
          <stop offset={1} stopColor="#FD5181" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default StoryIconFlower;
