/* eslint-disable max-len */
import { FC } from "react";

// types
import { GlyphIcon } from "../Icon.types";

const StoryIconChat: FC<GlyphIcon> = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      width={50}
      height={50}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z"
        fill="url(#paint0_linear_578_875)"
      />
      <path
        d="M40.625 25C40.625 17.2656 33.5937 10.9375 25 10.9375C16.4062 10.9375 9.375 17.2656 9.375 25C9.375 32.5 15.8594 38.5937 24.0625 38.9844C24.6094 41.875 25 43.75 25 43.75C25 43.75 28.9062 41.4062 32.5 37.3437C37.3437 34.9219 40.625 30.3125 40.625 25Z"
        fill="white"
      />
      <path
        d="M25 25C25.8629 25 26.5625 24.3004 26.5625 23.4375C26.5625 22.5746 25.8629 21.875 25 21.875C24.1371 21.875 23.4375 22.5746 23.4375 23.4375C23.4375 24.3004 24.1371 25 25 25Z"
        fill="url(#paint1_linear_578_875)"
      />
      <path
        d="M31.25 25C32.1129 25 32.8125 24.3004 32.8125 23.4375C32.8125 22.5746 32.1129 21.875 31.25 21.875C30.3871 21.875 29.6875 22.5746 29.6875 23.4375C29.6875 24.3004 30.3871 25 31.25 25Z"
        fill="url(#paint2_linear_578_875)"
      />
      <path
        d="M18.75 25C19.6129 25 20.3125 24.3004 20.3125 23.4375C20.3125 22.5746 19.6129 21.875 18.75 21.875C17.8871 21.875 17.1875 22.5746 17.1875 23.4375C17.1875 24.3004 17.8871 25 18.75 25Z"
        fill="url(#paint3_linear_578_875)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_578_875"
          x1={25}
          y1={0}
          x2={25}
          y2={50}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F1C54A" />
          <stop offset={1} stopColor="#DE8073" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_578_875"
          x1={25}
          y1={21.875}
          x2={25}
          y2={25}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F1C54A" />
          <stop offset={1} stopColor="#DE8073" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_578_875"
          x1={31.25}
          y1={21.875}
          x2={31.25}
          y2={25}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F1C54A" />
          <stop offset={1} stopColor="#DE8073" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_578_875"
          x1={18.75}
          y1={21.875}
          x2={18.75}
          y2={25}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F1C54A" />
          <stop offset={1} stopColor="#DE8073" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default StoryIconChat;
