// types
import { FAQ_TYPE } from "@/types";

export const instructionTextKeys = [
  {
    type: FAQ_TYPE.CHAT,
    title: "faq.instructions.chat.title",
    descTexts: [
      "faq.instructions.chat.description1",
      "faq.instructions.chat.description2",
      "faq.instructions.shared.description",
    ],
  },
  {
    type: FAQ_TYPE.SELF,
    title: "faq.instructions.self.title",
    descTexts: [
      "faq.instructions.self.description1",
      "faq.instructions.self.description2",
      "faq.instructions.shared.description",
    ],
  },
  {
    type: FAQ_TYPE.NO_HELP,
    title: "faq.instructions.noHelp.title",
    descTexts: [
      "faq.instructions.noHelp.description1",
      "faq.instructions.noHelp.description2",
      "faq.instructions.shared.description",
    ],
  },
  {
    type: FAQ_TYPE.NO_POLICE,
    title: "faq.instructions.noPolice.title",
    descTexts: [
      "faq.instructions.noPolice.description",
      "faq.instructions.shared.description",
    ],
  },
  {
    type: FAQ_TYPE.NO_PROFESSIONALS,
    title: "faq.instructions.noProfessionals.title",
    descTexts: [
      "faq.instructions.noProfessionals.description",
      "faq.instructions.shared.description",
    ],
  },
];
