import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

// components
import { BaseLayout, Button } from "@/components";

// hooks
import { useLocaleUrl } from "@/hooks";

const PeerSupportInstructions = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { toLocaleUrl } = useLocaleUrl();

  return (
    <BaseLayout backUrl={toLocaleUrl("/")}>
      <div className="koko-page__peer-support__instructions">
        <div className="header">
          <span className="title">{t("peerSupport.instructions.title")}</span>
        </div>
        <div className="content">
          <Button
            className="btn-continue"
            variant="primary"
            onClick={() => navigate(toLocaleUrl("/peer-support/platforms"))}
          >
            {t("shared.btnContinue.text", { defaultValue: "Continue" })}
          </Button>
        </div>
        <div className="footer">
          <p className="comment">
            <Trans i18nKey="peerSupport.instructions.footer.comment" />
          </p>
          <span className="author">{t("shared.text.anonymousUser")}</span>
        </div>
      </div>
    </BaseLayout>
  );
};

export { PeerSupportInstructions };
