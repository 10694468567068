// constants
import { supportedLocales } from "@/constants";

// types
import { LOCALE_CODES } from "@/types";

export const isLocaleMatch = (locale: string) => {
  return supportedLocales.findIndex(({ value }) => value === locale) >= 0;
};

export const getLocaleUrlPrefixByLang = (locale?: LOCALE_CODES) => {
  return locale === LOCALE_CODES.EN ? "/" : `/${locale}`;
};
