import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// components
import { BaseLayout, CTAList } from "@/components";
import type { CTA } from "@/components";

// hooks
import { useLocaleUrl } from "@/hooks";

// context
import { useAppContext } from "@/context";

// types
import { FAQ_TYPE } from "@/types";

const FAQOptions = () => {
  const { t } = useTranslation();

  const { setFaqType } = useAppContext();

  const navigate = useNavigate();

  const { toLocaleUrl } = useLocaleUrl();

  const ctaList: CTA[] = [
    {
      text: t("faq.options.cta1"),
      onClick: () => {
        setFaqType(FAQ_TYPE.CHAT);
        navigate(toLocaleUrl("/faq/instructions"));
      },
    },
    {
      text: t("faq.options.cta2"),
      onClick: () => {
        setFaqType(FAQ_TYPE.SELF);
        navigate(toLocaleUrl("/faq/instructions"));
      },
    },
    {
      text: t("faq.options.cta3"),
      onClick: () => {
        setFaqType(FAQ_TYPE.NO_HELP);
        navigate(toLocaleUrl("/faq/instructions"));
      },
    },
    {
      text: t("faq.options.cta4"),
      onClick: () => {
        setFaqType(FAQ_TYPE.NO_POLICE);
        navigate(toLocaleUrl("/faq/instructions"));
      },
    },
    {
      text: t("faq.options.cta5"),
      onClick: () => {
        setFaqType(FAQ_TYPE.NO_PROFESSIONALS);
        navigate(toLocaleUrl("/faq/instructions"));
      },
    },
  ];

  return (
    <BaseLayout backUrl={toLocaleUrl("/faq/introduction")}>
      <div className="koko-page__faq__options">
        <CTAList items={ctaList} />
      </div>
      ;
    </BaseLayout>
  );
};

export { FAQOptions };
