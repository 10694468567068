import { FC } from "react";
import cx from "clsx";

// components
import { Icon } from "@/components";

// types
import { ButtonProps } from "./Button.types";

const Button: FC<ButtonProps> = ({
  className = "",
  variant = "primary",
  icon,
  children,
  disabled = false,
  onClick,
}) => {
  const classes = cx({
    "koko-button": true,
    [`koko-button--${variant}`]: true,
    [className || ""]: className,
  });

  return (
    <button className={classes} onClick={onClick} disabled={disabled}>
      {icon && <Icon className="koko-button__icon" glyph={icon} />}
      {children}
    </button>
  );
};

export { Button };
