import { FC } from "react";
import { useTranslation } from "react-i18next";
import cx from "clsx";

// components
import { Button, Link, Icon } from "@/components";

// types
import { HelplineCardProps } from "./HelplineCard.types";

const HelplineCard: FC<HelplineCardProps> = ({
  className = "",
  name,
  url,
  availability,
  description,
  displayNumber,
  formattedCallNumber,
}) => {
  const { t } = useTranslation();

  const classes = cx({
    "koko-helpline-card": true,
    [className || ""]: className,
  });

  const onCallPhone = () => {
    window.location.href = `tel:${formattedCallNumber}`;
  };

  return (
    <div className={classes}>
      <div className="koko-helpline-card__head">
        {url ? (
          <Link variant="secondary" href={url} target="_blank">
            {name}
          </Link>
        ) : (
          <span>{name}</span>
        )}
        <p className="availability">{availability}</p>
      </div>
      <div className="koko-helpline-card__body">
        {description && <p className="description">{description}</p>}
        {displayNumber && (
          <div className="phone">
            <Icon glyph="phone" /> {displayNumber}
          </div>
        )}
      </div>
      <div className="koko-helpline-card__foot">
        {displayNumber && (
          <Button
            className="btn-call"
            variant="primary"
            icon="phone"
            onClick={onCallPhone}
          >
            {t("shared.text.call")}
          </Button>
        )}
      </div>
    </div>
  );
};

export { HelplineCard };
