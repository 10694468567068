/* eslint-disable max-len */
import { FC } from "react";

// types
import { GlyphIcon } from "../Icon.types";

const StoryIconTelegram: FC<GlyphIcon> = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M34 0H6C2.68629 0 0 2.68629 0 6V34C0 37.3137 2.68629 40 6 40H34C37.3137 40 40 37.3137 40 34V6C40 2.68629 37.3137 0 34 0Z"
        fill="#37AEE2"
      />
      <path
        d="M15.5469 31.5625C14.6875 31.5625 14.7656 31.25 14.5312 30.4688L12.0312 22.2656L31.1719 11.0156"
        fill="#C8DAEA"
      />
      <path
        d="M15.5469 31.5625C16.0938 31.5625 16.4062 31.25 16.7969 30.9375L20.3125 27.5781L15.9375 24.9219"
        fill="#A9C9DD"
      />
      <path
        d="M15.9377 24.9219L26.4845 32.6562C27.5783 33.3594 28.5158 32.9687 28.8283 31.5625L33.1252 11.4062C33.5158 9.68748 32.422 8.90623 31.2502 9.4531L6.17203 19.1406C4.53141 19.7656 4.53141 20.7812 5.85953 21.1719L12.3439 23.2031L27.1877 13.75C27.8908 13.3594 28.5158 13.5156 28.047 14.0625"
        fill="#F6FBFE"
      />
    </svg>
  );
};

export default StoryIconTelegram;
