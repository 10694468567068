import ArrowLeft from "./ArrowLeft";
import Check from "./Check";
import ChevronDown from "./ChevronDown";
import ChevronRight from "./ChevronRight";
import Email from "./Email";
import Phone from "./Phone";

// story icons
import StoryIconAtom from "./StoryIconAtom";
import StoryIconChat from "./StoryIconChat";
import StoryIconDiscord from "./StoryIconDiscord";
import StoryIconFlower from "./StoryIconFlower";
import StoryIconGlobe from "./StoryIconGlobe";
import StoryIconHeart from "./StoryIconHeart";
import StoryIconLamp from "./StoryIconLamp";
import StoryIconStar from "./StoryIconStar";
import StoryIconTelegram from "./StoryIconTelegram";
import StoryIconWhatsapp from "./StoryIconWhatsapp";

const glyphs = {
  "arrow-left": ArrowLeft,
  check: Check,
  "chevron-down": ChevronDown,
  "chevron-right": ChevronRight,
  email: Email,
  phone: Phone,
  "story-icon-atom": StoryIconAtom,
  "story-icon-chat": StoryIconChat,
  "story-icon-discord": StoryIconDiscord,
  "story-icon-flower": StoryIconFlower,
  "story-icon-globe": StoryIconGlobe,
  "story-icon-heart": StoryIconHeart,
  "story-icon-lamp": StoryIconLamp,
  "story-icon-star": StoryIconStar,
  "story-icon-telegram": StoryIconTelegram,
  "story-icon-whatsapp": StoryIconWhatsapp,
};

export interface Glyphs {
  glyph: keyof typeof glyphs;
}

export { glyphs };
