import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// components
import { BaseLayout, CTAList, Link, LanguageSelector } from "@/components";
import type { CTA } from "@/components";

// constants
import {
  supportedLocales,
  KOKO_CARES_URL,
  PRIVACY_POLICY_URL,
  TERMS_OF_SERVICE_URL,
} from "@/constants";

// hooks
import { useLocaleUrl } from "@/hooks";

// helpers
import { getLocaleUrlPrefixByLang } from "@/helpers";

// assets
import LogoBlue from "@/static/images/logo_blue.svg";
import KokoBlue from "@/static/images/koko_blue.svg";
import { LOCALE_CODES } from "@/types";

const Main = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { locale, toLocaleUrl } = useLocaleUrl();

  const onSelectLanguage = (newLang) => {
    const urlPrefix = getLocaleUrlPrefixByLang(newLang as LOCALE_CODES);
    navigate(urlPrefix);

    // storage to local storage
    localStorage.setItem("locale", newLang);
  };

  const ctaList: CTA[] = [
    {
      icon: "story-icon-chat",
      text: t("main.cta1"),
      link: toLocaleUrl("/countries"),
    },
    {
      icon: "story-icon-globe",
      text: t("main.cta2"),
      link: toLocaleUrl("/peer-support/instructions"),
    },
    {
      icon: "story-icon-lamp",
      text: t("main.cta3"),
      link: toLocaleUrl("/self-help-courses"),
    },
  ];

  return (
    <BaseLayout showBack={false}>
      <div className="koko-page__main">
        {/* Language Selector */}
        <LanguageSelector
          className="language-selector"
          selected={locale}
          options={supportedLocales}
          onSelect={onSelectLanguage}
        />

        {/* Header */}
        <div className="header">
          <img className="img-logo" src={LogoBlue} alt="logo-blue" />
          <p className="description">{t("main.header.description")}</p>
        </div>

        {/* Content */}
        <div className="content">
          <CTAList items={ctaList} />
        </div>

        {/* Footer */}
        <div className="footer">
          <div className="external-links">
            <Link className="link" variant="primary" href={PRIVACY_POLICY_URL}>
              {t("main.externalLinks.privacyPolicy")}
            </Link>
            <span className="divider" />
            <Link
              className="link"
              variant="primary"
              href={TERMS_OF_SERVICE_URL}
            >
              {t("main.externalLinks.termsOfService")}
            </Link>
          </div>
          <div className="copyright">
            <span> {t("main.copyright")}</span>
            <Link className="koko-link" href={KOKO_CARES_URL} target="_blank">
              <img src={KokoBlue} alt="koko" />
            </Link>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export { Main };
