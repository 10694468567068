/* eslint-disable max-len */
import { FC } from "react";

// types
import { GlyphIcon } from "../Icon.types";

const Phone: FC<GlyphIcon> = ({
  className,
  color = "currentColor",
  onClick,
}) => {
  return (
    <svg
      className={className}
      width={13}
      height={14}
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M12.285 9.44111C11.3967 9.44111 10.5372 9.29667 9.73555 9.03667C9.48278 8.95 9.20111 9.015 9.00611 9.21L7.87222 10.6328C5.82833 9.65778 3.91444 7.81611 2.89611 5.7L4.30444 4.50111C4.49944 4.29889 4.55722 4.01722 4.47778 3.76444C4.21056 2.96278 4.07333 2.10333 4.07333 1.215C4.07333 0.825 3.74833 0.5 3.35833 0.5H0.859444C0.469444 0.5 0 0.673333 0 1.215C0 7.92444 5.58278 13.5 12.285 13.5C12.7978 13.5 13 13.045 13 12.6478V10.1561C13 9.76611 12.675 9.44111 12.285 9.44111Z"
        fill={color}
      />
    </svg>
  );
};

export default Phone;
