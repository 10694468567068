import { useTranslation, Trans } from "react-i18next";

// components
import { BaseLayout, CTAList } from "@/components";
import type { CTA } from "@/components";

// hooks
import { useLocaleUrl } from "@/hooks";

// constants
import {
  CREATE_SAFETY_PLAN_URL,
  IMPROVE_MOOD_URL,
  MANAGE_SELF_HARM_URL,
  BODY_IMAGE_URL,
} from "@/constants";

const SelfHelpCourses = () => {
  const { t } = useTranslation();

  const { toLocaleUrl } = useLocaleUrl();

  const ctaList: CTA[] = [
    {
      icon: "story-icon-flower",
      text: t("selfHelpCourses.cta1"),
      onClick: () => {
        window.open(CREATE_SAFETY_PLAN_URL, "_blank");
      },
    },
    {
      icon: "story-icon-atom",
      text: t("selfHelpCourses.cta2"),
      onClick: () => {
        window.open(IMPROVE_MOOD_URL, "_blank");
      },
    },
    {
      icon: "story-icon-heart",
      text: t("selfHelpCourses.cta3"),
      onClick: () => {
        window.open(MANAGE_SELF_HARM_URL, "_blank");
      },
    },
    {
      icon: "story-icon-star",
      text: t("selfHelpCourses.cta4"),
      onClick: () => {
        window.open(BODY_IMAGE_URL, "_blank");
      },
    },
  ];

  return (
    <BaseLayout backUrl={toLocaleUrl("/")}>
      <div className="koko-page__self-help-courses">
        <div className="header">
          <span className="title">{t("selfHelpCourses.header.title")}</span>
          <p className="subtitle">
            <Trans key="selfHelpCourses.header.subtitle" />
          </p>
        </div>
        <div className="content">
          <CTAList items={ctaList} />
        </div>
        <div className="footer">
          <p className="comment">“{t("selfHelpCourses.footer.comment")}”</p>
          <span className="author">{t("shared.text.anonymousUser")}</span>
        </div>
      </div>
    </BaseLayout>
  );
};

export { SelfHelpCourses };
