/* eslint-disable max-len */
import { FC } from "react";

// types
import { GlyphIcon } from "../Icon.types";

const StoryIconStar: FC<GlyphIcon> = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      width={50}
      height={50}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z"
        fill="url(#paint0_linear_578_313)"
      />
      <path
        opacity={0.2}
        d="M41.3282 21.9531C41.0938 21.1719 40.4688 20.7031 39.6876 20.5469L30.8594 19.2969L26.8751 11.25C26.5626 10.5469 25.7813 10.0781 25.0001 10.0781C24.2188 10.0781 23.5157 10.5469 23.1251 11.25L19.1407 19.2969L10.3126 20.5469C9.53131 20.625 8.90631 21.1719 8.67193 21.9531C8.43756 22.7344 8.59381 23.5156 9.21881 24.0625L15.6251 30.3125L14.1407 39.0625C13.9844 39.8437 14.2969 40.625 14.9219 41.0937C15.3126 41.3281 15.7032 41.4844 16.0938 41.4844C16.4063 41.4844 16.7188 41.4062 17.0313 41.25L25.0001 37.1094L32.8907 41.25C33.2032 41.4062 33.5157 41.4844 33.8282 41.4844C34.2188 41.4844 34.6876 41.3281 35.0001 41.0937C35.6251 40.625 35.9376 39.8437 35.7813 39.0625L34.3751 30.2344L40.7813 23.9844C41.3282 23.5156 41.5626 22.6562 41.3282 21.9531Z"
        fill="#231F20"
      />
      <path
        d="M41.3282 20.3906C41.0938 19.6094 40.4688 19.1406 39.6876 18.9844L30.8594 17.7344L26.8751 9.6875C26.5626 8.98437 25.7813 8.51562 25.0001 8.51562C24.2188 8.51562 23.5157 8.98437 23.1251 9.6875L19.1407 17.7344L10.3126 18.9844C9.53131 19.0625 8.90631 19.6094 8.67193 20.3906C8.43756 21.1719 8.59381 21.9531 9.21881 22.5L15.6251 28.75L14.1407 37.5C13.9844 38.2812 14.2969 39.0625 14.9219 39.5312C15.3126 39.7656 15.7032 39.9219 16.0938 39.9219C16.4063 39.9219 16.7188 39.8437 17.0313 39.6875L25.0001 35.5469L32.8907 39.6875C33.2032 39.8437 33.5157 39.9219 33.8282 39.9219C34.2188 39.9219 34.6876 39.7656 35.0001 39.5312C35.6251 39.0625 35.9376 38.2812 35.7813 37.5L34.3751 28.6719L40.7813 22.4219C41.3282 21.9531 41.5626 21.0938 41.3282 20.3906Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_578_313"
          x1={25}
          y1={0}
          x2={25}
          y2={50}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F1C54A" />
          <stop offset={1} stopColor="#DE8073" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default StoryIconStar;
