import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// components
import { BaseLayout, Button } from "@/components";

// hooks
import { useLocaleUrl } from "@/hooks";

// context
import { useAppContext } from "@/context";

// constants
import { instructionTextKeys } from "./Instructions.constants";

const FAQInstructions = () => {
  const { t } = useTranslation();

  const { faqType } = useAppContext();

  const navigate = useNavigate();

  const { toLocaleUrl } = useLocaleUrl();

  const instruction = useMemo(
    () =>
      instructionTextKeys.find(({ type }) => type === faqType) ||
      instructionTextKeys[0],
    [faqType]
  );

  return (
    <BaseLayout backUrl={toLocaleUrl("/faq/options")}>
      <div className="koko-page__faq__instructions">
        <div className="content">
          <span className="title">{t(instruction.title)}</span>
          <div className="desc-texts">
            {instruction.descTexts.map((desc, idx) => (
              <p key={idx}>{t(desc)}</p>
            ))}
          </div>
        </div>
        <div className="footer">
          <Button
            className="btn-helplines"
            variant="primary"
            onClick={() => navigate(toLocaleUrl("/helplines"))}
          >
            {t("shared.text.helplines")}
          </Button>
          <Button
            className="btn-main-menu"
            variant="default"
            onClick={() => navigate(toLocaleUrl("/"))}
          >
            {t("shared.text.mainMenu")}
          </Button>
        </div>
      </div>
    </BaseLayout>
  );
};

export { FAQInstructions };
