import { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import cx from "clsx";

// types
import { LinkProps } from "./Link.types";

const Link: FC<LinkProps> = ({
  className = "",
  variant = "primary",
  href = "",
  target = "_blank",
  children,
  onClick,
}) => {
  const classes = cx({
    "koko-link": true,
    [`koko-link--${variant}`]: true,
    [className || ""]: className,
  });

  if (target === "_internal") {
    return (
      <RouterLink className={classes} to={href} onClick={onClick}>
        {children}
      </RouterLink>
    );
  }
  return (
    <a className={classes} href={href} target={target} onClick={onClick}>
      {children}
    </a>
  );
};

export { Link };
