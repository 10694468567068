import { FC, useState } from "react";
import { components, OptionProps } from "react-select";
import { useSwipeable } from "react-swipeable";
import { useTranslation } from "react-i18next";
import OutsideClickHandler from "react-outside-click-handler";
import cx from "clsx";

// components
import { Select, Option, Icon, Button } from "@/components";

// hooks
import { useResponsive } from "@/hooks";

// types
import { LanguageSelectorProps } from "./LanguageSelector.types";

const LanguageSelector: FC<LanguageSelectorProps> = ({
  className = "",
  options = [],
  selected,
  onSelect,
  ...props
}) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { isMobile } = useResponsive();
  const swipeHandlers = useSwipeable({
    onSwipedDown: () => setIsOpen(false),
  });

  const classes = cx({
    "koko-language-selector": true,
    [className || ""]: className,
  });

  const onSelectLanguage = (lang: string) => {
    onSelect?.(lang);
    setIsOpen(false);
  };

  const onMenuClose = () => {
    if (isMobile) return;
    setIsOpen(false);
  };

  const LanguageOption = ({
    isSelected,
    children,
    ...optionProps
  }: OptionProps<Option>) => (
    <components.Option isSelected={isSelected} {...optionProps}>
      {children}
      {isSelected && <Icon glyph="check" />}
    </components.Option>
  );

  return (
    <>
      <Select
        className={classes}
        components={{
          Option: LanguageOption,
        }}
        placeholder="Select Language"
        options={options}
        selected={selected}
        onSelect={onSelectLanguage}
        searchable={false}
        clearable={false}
        menuIsOpen={isOpen}
        onMenuOpen={() => setIsOpen(true)}
        onMenuClose={onMenuClose}
        {...props}
      />

      {/* Mobile Menu List */}
      {isMobile && (
        <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
          <div
            className={cx({
              "koko-language-selector__mb-menu": true,
              "koko-language-selector__mb-menu--open": isOpen,
            })}
            {...swipeHandlers}
          >
            <div className="koko-language-selector__mb-menu-list">
              <div className="koko-language-selector__mb-menu-list__header">
                <span
                  className="close-handler"
                  onClick={() => setIsOpen(false)}
                />
                <h3>{t("components.languageSelector.header.text")}</h3>
              </div>
              <ul className="koko-language-selector__mb-menu-list__options">
                {options.map(({ value, label }, idx) => (
                  <li
                    key={idx}
                    className={cx({
                      "koko-language-selector__mb-menu-list__option": true,
                      "koko-language-selector__mb-menu-list__option--active":
                        selected === value,
                    })}
                    onClick={() => onSelectLanguage(value)}
                  >
                    {label}
                  </li>
                ))}
              </ul>
            </div>
            <div className="koko-language-selector__mb-menu-action">
              <Button
                className="btn-close"
                variant="secondary"
                onClick={() => setIsOpen(false)}
              >
                {t("shared.btnClose.text", { defaultValue: "Close" })}
              </Button>
            </div>
          </div>
        </OutsideClickHandler>
      )}
    </>
  );
};

export { LanguageSelector };
