import { useMemo } from "react";
import { useTranslation, Trans } from "react-i18next";

// components
import { BaseLayout, HelplineCard } from "@/components";

// hooks
import { useCountries } from "@/hooks";

// context
import { useAppContext } from "@/context";

// types
import { TOPIC_TYPE } from "@/types";

// constants
import { helplines } from "@/constants";
import { topicI19nKeys } from "./Helplines.constants";

const Helplines = () => {
  const { t } = useTranslation();

  const { currentCountry, topicType } = useAppContext();

  const { getCountryName } = useCountries();

  const currentHelplines = useMemo(
    () =>
      helplines.filter((helpline) => {
        const isTopicMatch = [helpline.topic, TOPIC_TYPE.UNKNOWN].includes(
          topicType
        );
        return isTopicMatch && helpline.countryCode === currentCountry;
      }),
    [currentCountry, topicType]
  );

  return (
    <BaseLayout showHeader>
      <div className="koko-page__helplines">
        <p className="title">
          <span className="topic-type">
            {topicType !== TOPIC_TYPE.UNKNOWN && t(topicI19nKeys[topicType])}
          </span>{" "}
          <Trans
            i18nKey="helplines.title"
            values={{
              country: getCountryName(currentCountry),
            }}
          />
        </p>
        <div className="helplines">
          {currentHelplines.map((helpline, idx) => (
            <HelplineCard key={idx} {...helpline} />
          ))}
        </div>
      </div>
    </BaseLayout>
  );
};

export { Helplines };
