import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

// components
import { BaseLayout, CTAList, Link } from "@/components";
import type { CTA } from "@/components";

// hooks
import { useLocaleUrl } from "@/hooks";

// context
import { useAppContext } from "@/context";

// types
import { TOPIC_TYPE } from "@/types";

const Resources = () => {
  const { t } = useTranslation();

  const { setTopicType } = useAppContext();

  const navigate = useNavigate();

  const { toLocaleUrl } = useLocaleUrl();

  const ctaList: CTA[] = [
    {
      text: t("resources.cta1"),
      onClick: () => {
        setTopicType(TOPIC_TYPE.MENTAL_HEALTH);
        navigate(toLocaleUrl("/helplines"));
      },
    },
    {
      text: t("resources.cta2"),
      onClick: () => {
        setTopicType(TOPIC_TYPE.EATING_DISORDERS);
        navigate(toLocaleUrl("/helplines"));
      },
    },
    {
      text: t("resources.cta3"),
      onClick: () => {
        setTopicType(TOPIC_TYPE.LGBTQ);
        navigate(toLocaleUrl("/helplines"));
      },
    },
    {
      text: t("resources.cta4"),
      onClick: () => {
        setTopicType(TOPIC_TYPE.ADDICTION);
        navigate(toLocaleUrl("/helplines"));
      },
    },
    {
      text: t("resources.cta5"),
      onClick: () => {
        setTopicType(TOPIC_TYPE.ABUSE);
        navigate(toLocaleUrl("/helplines"));
      },
    },
  ];

  return (
    <BaseLayout backUrl={toLocaleUrl("/countries")}>
      <div className="koko-page__resources">
        <div className="header">
          <span className="title">{t("resources.header.title")}</span>
        </div>
        <div className="content">
          <CTAList items={ctaList} />
        </div>
        <div className="footer">
          <p className="link-to-faq">
            <Trans
              i18nKey="shared.linkToFAQ.description"
              values={{
                link: t("shared.linkToFAQ.text"),
              }}
            >
              Not sure? Read our{" "}
              <Link
                variant="primary"
                href={toLocaleUrl("/faq/introduction")}
                target="_internal"
              >
                FAQ
              </Link>{" "}
              on helplines
            </Trans>
          </p>
        </div>
      </div>
    </BaseLayout>
  );
};

export { Resources };
