import axios from "axios";

// config
import config from "@/config";

// types
import { GetIPInfoResponse } from "./IPInfoService.types";

const request = axios.create({
  baseURL: config.ipinfo.baseUrl,
  timeout: config.api.timeout,
  headers: {
    "Content-Type": "application/json",
  },
});

const ipInfoService = {
  getIpInfo: (): Promise<GetIPInfoResponse> => {
    return new Promise((resolve, reject) => {
      request
        .get("/json", {
          params: {
            token: config.ipinfo.token,
          },
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err?.response?.data?.errors || []);
        });
    });
  },
};

export { ipInfoService };
