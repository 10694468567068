import { FC } from "react";
import { useNavigate } from "react-router-dom";
import cx from "clsx";

// components
import { Icon } from "@/components";

// types
import { CTAListProps } from "./CTAList.types";

const CTAList: FC<CTAListProps> = ({ className = "", items = [] }) => {
  const navigate = useNavigate();

  const classes = cx({
    "koko-cta-list": true,
    [className || ""]: className,
  });

  return (
    <div className={classes}>
      {items.map(({ icon, text, link, onClick }, idx) => {
        const onClickCTA = () => {
          if (link) {
            navigate(link);
          }
          onClick?.();
        };
        return (
          <div className="koko-cta-list__item" key={idx} onClick={onClickCTA}>
            <div className="left-panel">
              {icon && <Icon glyph={icon} />}
              {text && <span>{text}</span>}
            </div>
            <div className="right-panel">
              <Icon glyph="chevron-right" />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export { CTAList };
