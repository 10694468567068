/* eslint-disable max-len */
import { FC } from "react";

// types
import { GlyphIcon } from "../Icon.types";

const StoryIconWhatsapp: FC<GlyphIcon> = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M34 0H6C2.68629 0 0 2.68629 0 6V34C0 37.3137 2.68629 40 6 40H34C37.3137 40 40 37.3137 40 34V6C40 2.68629 37.3137 0 34 0Z"
        fill="#25D366"
      />
      <path
        d="M9.58222 30.7031L10.676 25.625C9.27018 23.4076 8.72177 20.7536 9.13365 18.1607C9.54552 15.5678 10.8894 13.2143 12.9131 11.5417C14.9367 9.8692 17.5012 8.99256 20.1253 9.07629C22.7493 9.16002 25.2527 10.1984 27.1656 11.9965C29.0785 13.7947 30.2696 16.2291 30.5153 18.843C30.7611 21.4568 30.0446 24.0705 28.5003 26.1937C26.956 28.3169 24.69 29.8036 22.1275 30.3749C19.5651 30.9463 16.8822 30.5629 14.5822 29.2968L9.58222 30.7031Z"
        fill="#25D366"
        stroke="white"
        strokeWidth={2.33299}
      />
      <path
        d="M24.0621 21.3281C23.8277 21.1718 23.5933 21.0937 23.3589 21.4062L22.4214 22.6562C22.1871 22.8125 22.0308 22.8906 21.7183 22.7343C20.5464 22.1093 18.9058 21.4062 17.4996 19.0625C17.4214 18.75 17.5777 18.5937 17.7339 18.4375L18.4371 17.3437C18.5933 17.1875 18.5152 17.0312 18.4371 16.875L17.4996 14.6093C17.2652 13.9843 17.0308 14.0625 16.7964 14.0625H16.1714C16.0152 14.0625 15.7027 14.1406 15.3902 14.4531C13.6714 16.1718 14.3746 18.5937 15.6246 20.1562C15.8589 20.4687 17.4214 23.2812 20.7808 24.7656C23.2808 25.8593 23.8277 25.7031 24.5308 25.5468C25.3902 25.4687 26.2496 24.7656 26.6402 24.0624C26.7183 23.8281 27.1089 22.8125 26.7964 22.6562"
        fill="white"
      />
    </svg>
  );
};

export default StoryIconWhatsapp;
