import { useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

// components
import { BaseLayout, Button, Select, Link } from "@/components";

// hooks
import { useCountries, useLocaleUrl } from "@/hooks";

// context
import { useAppContext } from "@/context";

// types
import { TOPIC_TYPE } from "@/types";

// constants
import { helplines } from "@/constants";

const Countries = () => {
  const { t } = useTranslation();

  const { selectedCountry, setSelectedCountry, currentCountry, setTopicType } =
    useAppContext();

  const { countryOptions } = useCountries();

  const { toLocaleUrl } = useLocaleUrl();

  const currentHelplines = useMemo(
    () =>
      helplines.filter((helpline) => helpline.countryCode === currentCountry),
    [selectedCountry]
  );

  const navigate = useNavigate();

  const onContinue = useCallback(() => {
    if (currentHelplines[0].topicMenu) {
      navigate(toLocaleUrl("/resources"));
    } else {
      setTopicType(TOPIC_TYPE.UNKNOWN);
      navigate(toLocaleUrl("/helplines"));
    }
  }, [currentHelplines, toLocaleUrl]);

  return (
    <BaseLayout backUrl={toLocaleUrl("/")}>
      <div className="koko-page__countries">
        <div className="header">
          <p className="description">{t("countries.header.description")}</p>
        </div>
        <div className="content">
          <Select
            className="country-selector"
            selected={selectedCountry}
            options={countryOptions}
            placeholder={t("countries.countrySelector.placeholder")}
            onSelect={setSelectedCountry}
          />
          <Button
            className="btn-continue"
            variant="primary"
            disabled={currentHelplines.length < 1}
            onClick={onContinue}
          >
            {t("shared.btnContinue.text", { defaultValue: "Continue" })}
          </Button>
        </div>
        <div className="footer">
          <p className="link-to-faq">
            <Trans
              i18nKey="shared.linkToFAQ.description"
              values={{
                link: t("shared.linkToFAQ.text"),
              }}
            >
              Not sure? Read our{" "}
              <Link
                variant="primary"
                href={toLocaleUrl("/faq/introduction")}
                target="_internal"
              >
                FAQ
              </Link>{" "}
              on helplines
            </Trans>
          </p>
        </div>
      </div>
    </BaseLayout>
  );
};

export { Countries };
