import { useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";

// components
import type { Option } from "@/components";

// constants
import { helplines } from "@/constants";

const useCountries = () => {
  const { t } = useTranslation();

  const countryOptions: Option[] = useMemo(
    () =>
      helplines.reduce(
        (options: Option[], { countryI18nKey, countryCode: value }) => {
          const optIdx = options.findIndex((opt) => opt.value === value);
          if (optIdx >= 0) {
            return options;
          }
          return options.concat({
            value,
            label: t(`countries.countryName.${countryI18nKey}`),
          });
        },
        []
      ),
    [helplines, t]
  );

  const getCountryName = useCallback(
    (code: string) => {
      const countryOption = countryOptions.find(({ value }) => value === code);
      return countryOption?.label || "";
    },
    [countryOptions]
  );

  return {
    countryOptions,
    getCountryName,
  };
};

export { useCountries };
