export enum LOCALE_CODES {
  EN = "en",
  DE = "de",
  FR = "fr",
  ES = "es",
}

export enum TOPIC_TYPE {
  UNKNOWN = "unkown",
  MENTAL_HEALTH = "mental health",
  EATING_DISORDERS = "eating disorders",
  LGBTQ = "lgbtq",
  ADDICTION = "addiction",
  ABUSE = "abuse",
}

export enum FAQ_TYPE {
  UNKNOWN = "unknown",
  CHAT = "chat",
  SELF = "self",
  NO_HELP = "no-help",
  NO_POLICE = "no-police",
  NO_PROFESSIONALS = "no-professionals",
}

export type Helpline = {
  countryI18nKey: string;
  countryCode: string;
  name: string;
  description: string;
  url: string;
  displayUrl: string;
  phone: string;
  displayNumber: string;
  formattedCallNumber: string;
  formattedTextingNumber: string;
  availability: string;
  modality: string;
  identity: string;
  topic: TOPIC_TYPE;
  topicMenu: boolean;
  workingHours?: string;
};
