import { FC, useMemo } from "react";
import ReactSelect from "react-select";
import cx from "clsx";

// types
import { Option, SelectProps } from "./Select.types";

const Select: FC<SelectProps> = ({
  className = "",
  placeholder,
  selected,
  options = [],
  searchable = true,
  clearable = true,
  disabled = false,
  onSelect,
  ...props
}) => {
  const classes = cx({
    "koko-select": true,
    [className || ""]: className,
  });

  const value = useMemo(
    () => options.find((option) => option.value === selected),
    [selected, options]
  );

  const onChange = (option: Option | null) => {
    onSelect?.(option ? option.value : "");
  };

  return (
    <ReactSelect
      className={classes}
      classNamePrefix="koko-select"
      options={options}
      value={value}
      placeholder={placeholder}
      isSearchable={searchable}
      isClearable={clearable}
      isDisabled={disabled}
      onChange={(option) => onChange(option as Option)}
      {...props}
    />
  );
};

export { Select };
