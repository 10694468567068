import {
  useState,
  useEffect,
  useMemo,
  useContext,
  createContext,
  ReactNode,
} from "react";

// services
import { ipInfoService } from "@/services";

// helpers
import { isLocaleMatch } from "@/helpers";

// constants
import { supportedLocales } from "@/constants";

// types
import { TOPIC_TYPE, FAQ_TYPE, LOCALE_CODES } from "@/types";
import { AppContextType } from "./AppContext.types";

const AppContext = createContext<AppContextType>({
  defaultLocale: LOCALE_CODES.EN,
  currentCountry: "",
  selectedCountry: "",
  ipCountryCode: "",
  topicType: TOPIC_TYPE.UNKNOWN,
  faqType: FAQ_TYPE.UNKNOWN,
  setSelectedCountry: () => {},
  setIpCountryCode: () => {},
  setTopicType: () => {},
  setFaqType: () => {},
});

const useAppContext = () => useContext(AppContext);

const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const [ipCountryCode, setIpCountryCode] = useState<string>("");
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [topicType, setTopicType] = useState<TOPIC_TYPE>(TOPIC_TYPE.UNKNOWN);
  const [faqType, setFaqType] = useState<FAQ_TYPE>(FAQ_TYPE.UNKNOWN);

  const initCountry = async () => {
    try {
      const { country } = await ipInfoService.getIpInfo();
      setSelectedCountry(country);
      setIpCountryCode(country);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    initCountry();
  }, []);

  const defaultLocale = useMemo(() => {
    // default by local storage
    const localStorageLocale = localStorage.getItem("locale");
    if (localStorageLocale && isLocaleMatch(localStorageLocale)) {
      return localStorageLocale as LOCALE_CODES;
    }

    // default by ip
    const locale = supportedLocales.find(({ countryCodes = [] }) =>
      countryCodes.includes(ipCountryCode)
    );
    return locale?.value || LOCALE_CODES.EN;
  }, [ipCountryCode]);

  const contextValue = useMemo(
    () => ({
      defaultLocale,
      ipCountryCode,
      currentCountry: selectedCountry || "US",
      selectedCountry,
      topicType,
      faqType,
      setSelectedCountry,
      setIpCountryCode,
      setTopicType,
      setFaqType,
    }),
    [selectedCountry, topicType, faqType]
  );

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

export { useAppContext, AppContextProvider };
