import { FC } from "react";
import cx from "clsx";

// glyphs
import { glyphs } from "./glyphs";

// types
import { IconProps } from "./Icon.types";

const Icon: FC<IconProps> = ({ className = "", color, glyph, onClick }) => {
  const Glyph = glyphs[glyph];
  if (Glyph) {
    return (
      <Glyph
        className={cx("koko-icon", className)}
        color={color}
        onClick={onClick}
      />
    );
  }
  return null;
};

export { Icon };
