import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cx from "clsx";

import { Analytics } from "@vercel/analytics/react";

// components
import { Button } from "@/components";

// hooks
import { useLocaleUrl } from "@/hooks";

// types
import { BaseLayoutProps } from "./BaseLayout.types";

const BaseLayout: FC<BaseLayoutProps> = ({
  className = "",
  showHeader = false,
  showBack = true,
  backUrl,
  children,
}) => {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const { locale, isLocaleSupported } = useLocaleUrl();

  const classes = cx({
    "koko-base-layout": true,
    [className || ""]: className,
  });

  const onBack = () => {
    if (backUrl) {
      navigate(backUrl);
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (isLocaleSupported) return;
    navigate("/");
  }, [isLocaleSupported]);

  useEffect(() => {
    if (locale) {
      i18n.changeLanguage(locale);
    }
  }, [locale]);

  return (
    <div className={classes}>
      {showHeader && <header />}
      <main>
        {showBack && (
          <Button
            className="btn-back"
            variant="text"
            icon="arrow-left"
            onClick={onBack}
          >
            {t("shared.btnBack.text", { defaultValue: "Back" })}
          </Button>
        )}
        <div className="page-container">{children}</div>
        <Analytics />
      </main>
    </div>
  );
};

export { BaseLayout };
