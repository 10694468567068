import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

// components
import { BaseLayout, Button } from "@/components";

// hooks
import { useLocaleUrl } from "@/hooks";

const FAQIntroduction = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { toLocaleUrl } = useLocaleUrl();

  return (
    <BaseLayout backUrl={toLocaleUrl("/")}>
      <div className="koko-page__faq__introduction">
        <p className="description">
          <Trans i18nKey="faq.introduction.description" />
        </p>
        <Button
          className="btn-continue"
          variant="primary"
          onClick={() => navigate(toLocaleUrl("/faq/options"))}
        >
          {t("shared.btnContinue.text", { defaultValue: "Continue" })}
        </Button>
      </div>
    </BaseLayout>
  );
};

export { FAQIntroduction };
