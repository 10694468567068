import React from "react";
import ReactDOM from "react-dom/client";
import App from "@/App";

// i18n
import "@/i18n";

// contexts
import { AppContextProvider } from "@/context";

import "@/styles/index.less";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AppContextProvider>
      <App />
    </AppContextProvider>
  </React.StrictMode>
);
