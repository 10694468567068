import { LOCALE_CODES } from "@/types";

export type Locale = {
  value: LOCALE_CODES;
  label: string;
  countryCodes?: string[];
};

export const supportedLocales: Locale[] = [
  {
    value: LOCALE_CODES.EN,
    label: "English",
  },
  {
    value: LOCALE_CODES.DE,
    label: "Deutsch",
    countryCodes: ["DE"],
  },
  {
    value: LOCALE_CODES.FR,
    label: "Français",
    countryCodes: ["FR"],
  },
  {
    value: LOCALE_CODES.ES,
    label: "Española",
    countryCodes: ["ES", "MX", "EC"],
  },
];
