/* eslint-disable max-len */
import { FC } from "react";

// types
import { GlyphIcon } from "../Icon.types";

const ChevronDown: FC<GlyphIcon> = ({
  className,
  color = "currentColor",
  onClick,
}) => {
  return (
    <svg
      className={className}
      width={15}
      height={10}
      viewBox="0 0 15 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.438905 0.937714C0.72032 0.656649 1.10179 0.498779 1.49953 0.498779C1.89726 0.498779 2.27873 0.656649 2.56014 0.937714L7.50303 5.87935L12.4459 0.937715C12.7304 0.6726 13.1067 0.52827 13.4956 0.535131C13.8844 0.541991 14.2554 0.699507 14.5304 0.974493C14.8054 1.24948 14.9629 1.62047 14.9697 2.0093C14.9766 2.39812 14.8323 2.77444 14.5672 3.05895L8.56365 9.06246C8.28223 9.34352 7.90076 9.50139 7.50303 9.50139C7.10529 9.50139 6.72383 9.34352 6.44241 9.06246L0.438905 3.05895C0.15784 2.77754 -3.06005e-05 2.39607 -3.05831e-05 1.99833C-3.05657e-05 1.6006 0.15784 1.21913 0.438905 0.937714Z"
        fill={color}
      />
    </svg>
  );
};

export default ChevronDown;
