/* eslint-disable max-len */
import { FC } from "react";

// types
import { GlyphIcon } from "../Icon.types";

const StoryIconDiscord: FC<GlyphIcon> = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M31.6467 10.1115C35.3295 15.5596 37.1482 21.705 36.4683 28.7797C36.4655 28.8097 36.45 28.8371 36.4255 28.8552C33.6366 30.9157 30.9346 32.1662 28.2705 32.9954C28.2498 33.0018 28.2275 33.0015 28.2071 32.9944C28.1865 32.9875 28.1686 32.9741 28.156 32.9565C27.5405 32.0948 26.9812 31.1864 26.4913 30.2324C26.4631 30.1762 26.4889 30.1085 26.5467 30.0864C27.4349 29.7497 28.2795 29.3461 29.0918 28.8683C29.1558 28.8306 29.1599 28.7383 29.1008 28.694C28.9284 28.5652 28.7576 28.4299 28.5942 28.2945C28.5636 28.2695 28.5224 28.2646 28.4877 28.2814C23.2137 30.7319 17.4363 30.7319 12.0999 28.2814C12.0652 28.2659 12.0241 28.2712 11.9943 28.2958C11.8313 28.4311 11.6601 28.5652 11.4893 28.694C11.4302 28.7383 11.4351 28.8306 11.4995 28.8683C12.3118 29.3371 13.1564 29.7497 14.0433 30.0881C14.1008 30.1102 14.1281 30.1762 14.0996 30.2324C13.6203 31.1876 13.061 32.0961 12.4341 32.9577C12.4068 32.9926 12.362 33.0086 12.3196 32.9954C9.66808 32.1662 6.96608 30.9157 4.17718 28.8552C4.15397 28.8371 4.13725 28.8085 4.13479 28.7785C3.5666 22.6589 4.72463 16.4627 8.95189 10.1102C8.96213 10.0934 8.97763 10.0803 8.99551 10.0725C11.0756 9.11193 13.3039 8.4053 15.633 8.00175C15.6754 7.9952 15.7178 8.01486 15.7398 8.0526C16.0276 8.56523 16.3565 9.22271 16.5791 9.75999C19.0341 9.38265 21.5275 9.38265 24.0338 9.75999C24.2564 9.23418 24.5739 8.56523 24.8605 8.0526C24.8706 8.0339 24.8865 8.01888 24.9057 8.00974C24.9248 8.00059 24.9464 7.99779 24.9672 8.00175C27.2976 8.40653 29.526 9.11316 31.6043 10.0725C31.6226 10.0803 31.6377 10.0934 31.6467 10.1115ZM17.8272 21.7468C17.8528 19.9377 16.542 18.4407 14.8965 18.4407C13.2644 18.4407 11.9662 19.9246 11.9662 21.7468C11.9662 23.5686 13.2901 25.0525 14.8965 25.0525C16.5289 25.0525 17.8272 23.5686 17.8272 21.7468ZM28.6622 21.7468C28.6879 19.9377 27.377 18.4407 25.7319 18.4407C24.0994 18.4407 22.8012 19.9246 22.8012 21.7468C22.8012 23.5686 24.1251 25.0525 25.7319 25.0525C27.377 25.0525 28.6622 23.5686 28.6622 21.7468Z"
        fill="#5865F2"
      />
    </svg>
  );
};

export default StoryIconDiscord;
